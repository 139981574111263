// ? import { Name } from './names' here if you want to use it in the config
// ? then add the Name[variant] to the relevant path below

const activeCampaignsConfig = {
  homepage: [],
  'gift-finder': {
    results: [],
  },
  product: [],
  collection: {
    _: [],
    product: [],
  },
  category: {
    c: [],
    cor: [],
    f: [],
    location: [],
    o: [],
    price: [],
    rating: [],
  },
};

export const activeCampaigns = Object.freeze(activeCampaignsConfig);
