import { type ICampaignPathConfig } from '../types';

import { slugs } from './slugs';
import { activeCampaigns } from './active-campaigns';

const {
  homepage,
  'gift-finder': giftFinder,
  collection,
  product,
  category: { c, cor, location, price, rating, f, o },
} = slugs;

const {
  homepage: homepageCampaigns,
  'gift-finder': giftFinderCampaigns,
  collection: collectionCampaigns,
  product: productCampaigns,
  category: {
    c: cCampaigns,
    cor: corCampaigns,
    f: fCampaigns,
    location: locationCampaigns,
    o: oCampaigns,
    price: priceCampaigns,
    rating: ratingCampaigns,
  },
} = activeCampaigns;

const config: ICampaignPathConfig = {
  // homepage
  _: { paths: [{ slugs: homepage, campaigns: homepageCampaigns }] },
  // gift finder results
  'gift-finder': {
    results: { paths: [{ slugs: giftFinder.results, campaigns: giftFinderCampaigns.results }] },
  },
  // collection PDPs and product choices
  collection: {
    paths: [{ slugs: collection._, campaigns: collectionCampaigns._ }],
    '[slug]': {
      product: { paths: [{ slugs: collection.product, campaigns: collectionCampaigns.product }] },
    },
  },
  // PDP
  product: { paths: [{ slugs: product, campaigns: productCampaigns }] },
  // categories
  c: {
    paths: [{ slugs: c, campaigns: cCampaigns }],
    plp: { paths: [] },
  },
  cor: {
    paths: [{ slugs: cor, campaigns: corCampaigns }],
    plp: { paths: [] },
  },
  location: {
    paths: [{ slugs: location, campaigns: locationCampaigns }],
    plp: { paths: [] },
  },
  o: {
    paths: [{ slugs: o, campaigns: oCampaigns }],
    plp: { paths: [] },
  },
  price: {
    paths: [{ slugs: price, campaigns: priceCampaigns }],
    plp: { paths: [] },
  },
  rating: {
    paths: [{ slugs: rating, campaigns: ratingCampaigns }],
    plp: { paths: [] },
  },
  f: {
    paths: [{ slugs: f, campaigns: fCampaigns }],
    plp: { paths: [] },
  },
};

export const pathsConfig = Object.freeze(config);
