import { DyBuildUtils } from './utils';
import { campaign } from '../../campaign';

import type { IDyBuild, VedCampaignPayload } from '../../types';

export class DyBuild extends DyBuildUtils implements IDyBuild {
  /**
   * Returns an array of paths for the given path and slugs.
   * For use in getStaticPaths
   */
  public generateSSGPaths(path: string, slugs: string[], paginationPrefix = ''): string[] {
    try {
      const { slugs: slugsToInclude, variationPaths } = this.variationsFromPath(
        path,
        campaign.config,
        campaign.variations
      );

      let paths: string[] = [];

      if (slugsToInclude === '*') {
        paths = slugs.flatMap((slug) =>
          variationPaths.map(
            (variation) => `/variation/${variation}/${path}/${paginationPrefix}${slug}`
          )
        );
      } else if (Array.isArray(slugsToInclude) && slugsToInclude.length) {
        paths = slugsToInclude.flatMap((slug) =>
          variationPaths.map(
            (variation) => `/variation/${variation}/${path}/${paginationPrefix}${slug}/`
          )
        );
      }

      return paths;
    } catch (error) {
      console.error(
        `[DY Build Error]: ${error instanceof Error ? error.message : 'Unknown error'}`
      );
    }
  }

  /**
   *
   */
  public variationDataFromPath(path: string, variationId: string): VedCampaignPayload | null {
    try {
      const { variations } = this.variationsFromPath(path, campaign.config, campaign.variations);

      const variation = variations.find(
        ({ variation }) => variation === variationId
      ) as VedCampaignPayload;

      return variation ?? null;
    } catch (error) {
      console.error(
        `[DY Build Error]: ${error instanceof Error ? error.message : 'Unknown error'}`
      );
    }
  }
}
