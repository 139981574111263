import { campaign } from '../../campaign';
import { DyApiUtils } from './utils';
import { isEnableServer } from '@virginexperiencedays/feature-flags';
import { CHOOSE_ENDPOINT } from './constants';

import type { Name } from '../../campaign/names';
import type {
  DYApiCookieData,
  DYApiChoice,
  IDyApiOptionsBody,
  IVedCampaignTemplatePayload,
  IDyAPI,
  CampaignSlugAndName,
  DyApiDecision,
  ICampaignPathConfig,
  DYApiWarning,
  DYRecommendationApiBody,
  DYRecommendationRequestData,
  Product,
  DYApiPage,
  DYApiUser,
  DYApiSession,
  DyApiContextPageType,
} from '../../types';

export class DyAPI extends DyApiUtils implements IDyAPI {
  private locationUrl: string;
  private endpoint: string;
  private apiKey: string;
  private locale = 'en_GB';
  public isEnabled = false;

  constructor(endpoint: string, apiKey: string, locationUrl: string, locale = 'en_GB') {
    super();

    this.endpoint = endpoint;
    this.apiKey = apiKey;
    this.locationUrl = locationUrl;
    this.locale = locale;
    this.isEnabled = isEnableServer('FF_ab_tests');

    // this is not really a "hook" so is OK to use here
    if (this.isEnabled) {
      if (!this.apiKey) throw new Error('[Error Confirming Credentials]: Missing API key');
      if (!this.endpoint)
        throw new Error('[Error Confirming Credentials]: Missing endpoint string');
    }
  }

  /**
   * Makes a POST request to the DyApi endpoint with the provided body and returns the JSON response from the API.
   */
  private async fetchFromDyApi(body: Record<string, any>, path: string) {
    try {
      if (!body) throw new Error('Missing "body" object');

      const jsonBody = JSON.stringify(body);

      const res = await fetch(`${this.endpoint}/${path}`, {
        method: 'POST',
        headers: {
          'DY-API-Key': this.apiKey,
          'Content-Type': 'application/json',
        },
        body: jsonBody,
      });

      const json = await res.json();
      return json;
    } catch (error) {
      console.error(
        `[DY API - Fetch Error]: ${error instanceof Error ? error.message : 'Unknown error'}`
      );
    }
  }

  /**
   * Extracts and returns the choices and cookies from the API response.
   * This is used to extract relevant data from the API response for custom code API.
   */
  private getDataFromCustomCodeResponse(response: any): {
    decision: DyApiDecision | null;
    choices: DYApiChoice[];
    cookies: DYApiCookieData[];
  } {
    const choicesArr: DYApiChoice[] = response?.choices ?? [];
    const cookiesArr: DYApiCookieData[] = response?.cookies ?? [];
    const decision: DyApiDecision | null = response?.choices?.[0]?.type ?? null;
    const warningsArr: DYApiWarning[] = response?.warnings ?? [];

    //Log warnings returned from the DY API.
    warningsArr.forEach((warning) => {
      console.warn(`[DY API - Warning]: Code: ${warning.code} Message:  ${warning.message} `);
    });

    const choices = this.filterValidChoices(choicesArr);
    const cookies = this.validateCookies(cookiesArr);

    return { decision, choices, cookies };
  }

  /**
   * extracts the campaign slug and name values from the config object using the split
   * path segments as the respective keys to access the nested values
   */
  private campaignSlugAndNameFromConfig(
    path: string,
    config: ICampaignPathConfig
  ): CampaignSlugAndName {
    const fallbackReturn: CampaignSlugAndName = { slug: null, name: null };

    // split the path into segments and extract the basePath and slug
    const { basePath, segments, slug } = this.segmentsFromPath(path);
    if (!basePath) return fallbackReturn;

    // ensure the path is valid within the campaign config object
    const validBasePaths = Object.keys(campaign.config);
    if (!validBasePaths.includes(basePath)) return fallbackReturn;

    // return paths array from config object using segments as keys
    // i.e. /collection/[slug]/product => config.collection['[slug]'].product
    const paths = this.pathsFromConfigObject([basePath, ...segments], config);
    if (!Array.isArray(paths) || !paths.length) return fallbackReturn;

    let name: Name | null = null;
    const pathsWithAppliedCampaign: string[] = [];

    // iterate over the campaigns array to find the campaign that matches the path
    // if the path matches a campaign, set the campaign name and push the slug to `pathsWithAppliedCampaign`
    for (const campaignPaths of paths) {
      if (pathsWithAppliedCampaign.includes(slug)) continue;

      // ! we currently only support one campaign per path, hence the [0] index
      const firstCampaign = campaignPaths?.campaigns?.[0];
      if (!firstCampaign) continue;

      switch (true) {
        // if slugs is an Array of slugs
        case Array.isArray(campaignPaths.slugs):
          if (campaignPaths.slugs.includes(slug)) {
            name = firstCampaign;
            pathsWithAppliedCampaign.push(slug);
          }
          break;
        // if all slug values are valid
        case campaignPaths.slugs === '*':
          name = firstCampaign;
          pathsWithAppliedCampaign.push(slug);
          break;
        // homepage
        case basePath === '_':
          name = firstCampaign;
          break;
      }
    }

    return { slug, name };
  }

  /**
   * returns a DYApiPage object for the given formattedPath & path
   */
  private getPageFromPath(formattedPath: string, slug: string): DYApiPage {
    const { basePath } = this.segmentsFromPath(formattedPath);
    const type = this.getPageTypeFromPath(basePath);

    return {
      type,
      data: [],
      location: `${this.locationUrl}/${slug}`,
      locale: this.locale,
    };
  }

  /**
   * returns DYApiUser and DYApiSession objects for the given cookies
   */
  private getUserAndSessionFromCookies(dyCookies: Record<string, string>): {
    user: DYApiUser;
    session: DYApiSession;
  } {
    const dyid_server = dyCookies?.['_dyid_server'];
    const dyjsession = dyCookies?.['_dyjsession'];

    const user = { dyid: dyid_server, dyid_server };
    const session = { dy: dyjsession };

    return { user, session };
  }

  /**
   * return the DY context pageType for the given path
   */
  public getPageType(path: string): DyApiContextPageType {
    const formattedPath = this.formatPath(path);
    const { basePath } = this.segmentsFromPath(formattedPath);
    const type = this.getPageTypeFromPath(basePath);
    return type;
  }

  /**
   * Performs a custom code API request to the DyApi endpoint.
   * It sends the selector, context, and options in the request body.
   * Returns the chosen variation and the received cookies.
   * This method is used to fetch and process custom code API responses.
   */
  public async choose(
    path: string,
    dyCookies: Record<string, string>,
    options: IDyApiOptionsBody = { isImplicitPageview: true }
  ): Promise<{
    choice: IVedCampaignTemplatePayload['data'];
    cookies: DYApiCookieData[];
    campaignName?: string;
  }> {
    try {
      if (!this.isEnabled) return { choice: null, cookies: [] };

      const formattedPath = this.formatPath(path);
      if (!formattedPath) throw new Error('Missing "path" string');

      const { slug, name } = this.campaignSlugAndNameFromConfig(formattedPath, campaign.config);
      if (!name) return { choice: null, cookies: [] };

      const page = this.getPageFromPath(formattedPath, slug);
      const { user, session } = this.getUserAndSessionFromCookies(dyCookies);

      const body = {
        selector: { names: [name] },
        context: {
          page,
        },
        options,
        user,
        session,
      };

      if (typeof slug === 'string') body.context.page.data.push(slug);

      const json = await this.fetchFromDyApi(body, CHOOSE_ENDPOINT);

      const { cookies, choices } = this.getDataFromCustomCodeResponse(json);

      const choice = this.extractVariationFromChoice(
        choices
      ) as IVedCampaignTemplatePayload['data'];

      return { choice, cookies, campaignName: name };
    } catch (error) {
      console.error(
        `[DY API - Choose Error]: ${error instanceof Error ? error.message : 'Unknown error'}`
      );
      return { choice: null, cookies: [] };
    }
  }

  /**
   * Performs a custom code API request to the DyApi endpoint.
   * It sends the context, and options in the request body.
   * This method is used to log a page view when we use the cached API responses.
   */
  public async pageView(
    path: string,
    dyCookies: Record<string, string>,
    options: IDyApiOptionsBody = { isImplicitPageview: true }
  ): Promise<void> {
    try {
      const endpoint = '/collect/user/pageview';

      const formattedPath = this.formatPath(path);
      if (!formattedPath) throw new Error('Missing "path" string');

      const { slug } = this.campaignSlugAndNameFromConfig(formattedPath, campaign.config);
      const { user, session } = this.getUserAndSessionFromCookies(dyCookies);

      const page = this.getPageFromPath(formattedPath, slug);

      const body = {
        context: {
          page,
        },
        options,
        user,
        session,
      };

      if (typeof slug === 'string') body.context.page.data.push(slug);

      this.fetchFromDyApi(body, endpoint);
    } catch (error) {
      console.error(
        `[DY API Page View Error]: PageView: ${
          error instanceof Error ? error.message : 'Unknown error'
        }`
      );
    }
  }

  /**
   * Performs a custom code API request to the DyApi endpoint.
   * It sends the selector, context, and options in the request body.
   * Returns the recommended products and the received cookies.
   */
  public async recommendationBySku({
    campaign,
    path,
    dyCookies,
    sku,
    options,
  }: Omit<DYRecommendationRequestData, 'conditions'> & {
    sku: string;
  }): Promise<{ choices: Product[] | string[]; cookies: DYApiCookieData[] }> {
    try {
      if (!campaign) throw new Error('Missing "campaign" string');

      const formattedPath = this.formatPath(path);
      if (!formattedPath) throw new Error('Missing "path" string');

      const page = this.getPageFromPath(formattedPath, path);
      const { user, session } = this.getUserAndSessionFromCookies(dyCookies);

      const body: DYRecommendationApiBody = {
        selector: {
          names: [campaign],
          groups: [],
          args: {},
        },
        context: {
          page: {
            ...page,
            data: [sku], // this will and should only ever be a single SKU
          },
        },
        events: [],
        options,
        user,
        session,
      };

      const json = await this.fetchFromDyApi(body, CHOOSE_ENDPOINT);

      const { choices, cookies } = this.getDataFromCustomCodeResponse(json);

      const productSlots = this.extractProductSlotsFromChoice(choices);
      if (!productSlots) throw new Error('Product slots not found in the response');

      const products = options?.recsProductData?.skusOnly
        ? productSlots.map(({ sku }) => sku)
        : this.mapProductSlotsToProducts(productSlots);

      return { choices: products, cookies };
    } catch (error) {
      console.error(
        `[DY API - Campaign Recommendation Error]: ${
          error instanceof Error ? error.message : 'Unknown error'
        }`
      );
      return { choices: null, cookies: [] };
    }
  }

  /**
   * Performs a custom code API request to the DyApi endpoint.
   * It sends the selector, context, and options in the request body.
   * Returns the recommended products and the received cookies.
   */
  public async recommendation({
    campaign,
    conditions,
    path,
    dyCookies,
    options,
  }: DYRecommendationRequestData): Promise<{
    choices: Product[] | string[];
    cookies: DYApiCookieData[];
  }> {
    try {
      if (!campaign) throw new Error('Missing "campaign" string');
      if (!conditions) throw new Error('Missing "conditions" object');

      const formattedPath = this.formatPath(path);
      if (!formattedPath) throw new Error('Missing "path" string');

      const queryConditions = this.buildRecommendationConditions(conditions);
      const { user, session } = this.getUserAndSessionFromCookies(dyCookies);

      const page = this.getPageFromPath(formattedPath, path);

      const body: DYRecommendationApiBody = {
        selector: {
          names: [campaign],
          groups: [],
          args: {
            [campaign]: {
              realtimeRules: [
                {
                  type: 'include',
                  slots: [],
                  query: {
                    conditions: queryConditions,
                  },
                },
              ],
            },
          },
        },
        context: {
          page,
        },
        events: [],
        options,
        user,
        session,
      };

      const json = await this.fetchFromDyApi(body, CHOOSE_ENDPOINT);

      const { choices, cookies } = this.getDataFromCustomCodeResponse(json);

      const productSlots = this.extractProductSlotsFromChoice(choices);
      if (!productSlots) throw new Error('Product slots not found in the response');

      const products = options?.recsProductData?.skusOnly
        ? productSlots.map(({ sku }) => sku)
        : this.mapProductSlotsToProducts(productSlots);

      return { choices: products, cookies };
    } catch (error) {
      console.error(
        `[DY API - Recommendation Error]: ${
          error instanceof Error ? error.message : 'Unknown error'
        }`
      );
      return { choices: null, cookies: [] };
    }
  }
}
