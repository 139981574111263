/**
 * use this object to configure the names for the campaigns you want to test
 * Campaign names are synonymous with the `DY API Selector`
 */

export enum PersistentCampaigns {
  // ! do not remove these campaigns
  gift_finder = 'gift_finder_spike_test',
}

export enum Name {
  CRO_671_Driving = 'CRO_671_Driving_Layout',
  CR0_672_Location = 'CRO_672_Location_Layout',
}
