import type { IVedCampaignTemplatePayload, ICampaignVariationsConfig } from '../types';
import { Name } from './names';

// this template for the variations object is based on the template in the DY dashboard
// ! do not alter this - it can be extended if needed in the `variationsConfig` object
export const variationsTemplate: readonly IVedCampaignTemplatePayload['data'][] = Object.freeze([
  { variation: 'a', defaultVariation: 'true' },
  { variation: 'b', defaultVariation: 'false' },
]);

/**
 * use this object to configure the variations for the campaigns you want to test
 * each variation should include the matching values from DY, and also any other
 * important flags the campaign requires
 */
const variationsConfig: ICampaignVariationsConfig = {
  [Name.CRO_671_Driving]: [...variationsTemplate],
  [Name.CR0_672_Location]: [...variationsTemplate],
};

export const variations = Object.freeze(variationsConfig);
