/**
 * use this object to configure the slugs for the campaigns you want to test
 *
 * ['driving', 'gifts-for-her']  - an array of explicit slugs to target those paths only
 * '*'                           - a wildcard to target all paths
 * `null`                        - only valid for homepage and custom site pages
 */
const slugsConfig = {
  homepage: null,
  'gift-finder': {
    results: [],
  },
  product: [],
  collection: {
    _: [],
    product: [],
  },
  category: {
    c: ['driving'],
    cor: [],
    f: [],
    location: ['wales', 'west-midlands', 'north-west', 'south-west', 'yorkshire-and-humber'],
    o: [],
    price: [],
    rating: [],
  },
};

export const slugs = Object.freeze(slugsConfig);
