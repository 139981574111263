import {
  CampaignPathData,
  PathSegmentData,
  ICampaignPathConfig,
  ICampaignVariationsConfig,
  IVedCampaignTemplatePayload,
} from '../../types';
import { CategoryPathTypes } from './path-types';

/**
 * remove leading and trailing slashes from a path
 */
export class SharedUtils {
  /**
   * opted for a function which utilises string replace rather than regex due to security concerns
   * it also only allows a path with up to 5 slashed on each end before throwing an error
   */
  public removeLeadingAndTrailingSlashes(path: string): string {
    if (this.hasExcessiveSlashes(path)) {
      throw new Error('String contains more than 5 leading or trailing slashes. Exiting...');
    }

    if (!path?.length) return path;

    let startIndex = 0;
    let endIndex = path.length;

    while (startIndex < endIndex && path[startIndex] === '/') startIndex++;
    while (startIndex < endIndex && path[endIndex - 1] === '/') endIndex--;

    return path.slice(startIndex, endIndex);
  }

  protected hasExcessiveSlashes(str: string, maxSlashes = 2): boolean {
    let leadingSlashes = 0;
    let trailingSlashes = 0;
    let i = 0;
    let j = str.length - 1;

    while (i < str.length && str[i] === '/') {
      leadingSlashes++;
      i++;
    }

    while (j >= 0 && str[j] === '/') {
      trailingSlashes++;
      j--;
    }

    return leadingSlashes > maxSlashes || trailingSlashes > maxSlashes;
  }

  /**
   * apply a race condition to a promise to ensure that it resolves within a given time
   * or otherwise reject with an error
   */
  protected async fetchWithTimeout<T>(promise: Promise<T>, timeout: number): Promise<T> {
    const timeoutPromise = new Promise<T>((_, reject) => {
      setTimeout(() => {
        reject(new Error('Request timed out'));
      }, timeout);
    });

    return Promise.race([promise, timeoutPromise]);
  }

  /**
   * format a given path in a specific way (to be defined within method)
   */
  protected formatPath(path: string): string | null {
    if (typeof path !== 'string') return null;
    if (!path.length) return '_'; // homepage
    return path; // other
  }

  /**
   * return the paths array from a config object using the given segments as keys
   */
  protected pathsFromConfigObject(
    segments: string[],
    config: ICampaignPathConfig
  ): CampaignPathData[] | null {
    if (!Array.isArray(segments) || !segments.length) return null;

    // handle cor pages differently
    let formattedSegments = [...segments];
    if (segments?.[0] === 'cor') formattedSegments = [segments[0]];

    const data: any = formattedSegments.reduce((acc, key) => acc?.[key], config);
    const paths: CampaignPathData[] = data?.paths;

    if (!Array.isArray(paths) || !paths.length) return null;
    return paths;
  }

  /**
   * this method takes in the path (excluding slug) for use in getStaticPaths
   * it returns the slug value of ("*" | string[] | null)
   */
  protected variationsFromPath(
    path: string,
    config: ICampaignPathConfig,
    variations: ICampaignVariationsConfig
  ): {
    slugs: CampaignPathData['slugs'];
    variations: IVedCampaignTemplatePayload['data'][];
    variationPaths: string[];
  } {
    const fallbackReturn = { slugs: [], variations: [], variationPaths: [] };
    const { basePath, segments } = this.segmentsFromPath(path);

    // return paths array from config object using segments as keys
    // i.e. /collection/[slug]/product => config.collection['[slug]'].product
    const paths = this.pathsFromConfigObject([basePath, ...segments], config);
    if (!Array.isArray(paths) || !paths.length) return fallbackReturn;

    // ! we will currently only support a single campaign per route
    const firstPath = paths?.[0];
    if (!firstPath) return fallbackReturn;

    const { slugs, campaigns } = firstPath;
    if (!slugs || !campaigns) return fallbackReturn;

    const firstCampaign = campaigns?.[0];
    if (!firstCampaign) return fallbackReturn;

    const campaignVariations = variations?.[firstCampaign];
    if (!Array.isArray(campaignVariations)) return fallbackReturn;

    const filteredVariations = campaignVariations.filter(
      ({ defaultVariation }) => defaultVariation === 'false'
    );

    const variationPaths = filteredVariations.map(({ variation }) => variation);

    return { slugs, variations: filteredVariations, variationPaths };
  }

  /**
   * extract the base path (i.e. 'product', 'collection', etc), slug, and all other segments of a path
   * return segments as an array of strings, omitting the basePath and slug
   * any path reformatting should be handled here (i.e. collection product choices)
   */
  protected segmentsFromPath(path: string): PathSegmentData {
    const fallbackReturn = { basePath: '', segments: [], slug: null };

    const splitPath = path.split('/').filter((segment) => !!segment);
    const basePath = splitPath[0];
    const segments = splitPath.slice(1, splitPath.length - 1);
    const slug = splitPath.at(-1);

    // homepage
    if (splitPath?.[0] === '_') {
      return { ...fallbackReturn, basePath: '_' };
    }
    // collection product choice
    if (basePath === 'collection' && splitPath.length === 4) {
      const copiedArray = segments.slice();
      copiedArray[0] = '[slug]';
      return { basePath, segments: copiedArray, slug };
    }
    // custom site page
    if (splitPath[0] === slug) {
      return { ...fallbackReturn, basePath };
    }
    // gift finder results page
    // NOTE: relax GF-results-specific condition as we A/B more GF pages
    if (splitPath[0] === 'gift-finder' && splitPath[1] === 'results') {
      // basePath: gift-finder, segments: results, slug: null
      return { ...fallbackReturn, basePath, segments: [...segments, slug] };
    }

    const isCategoryPage = Object.values(CategoryPathTypes).find((type) => type === basePath);

    // all other non-category pages
    if (!isCategoryPage) return { ...fallbackReturn, basePath, slug };

    // category pages
    switch (basePath) {
      case 'cor': {
        const formattedSlug = `${segments.join('/')}/${slug}`;
        return { ...fallbackReturn, basePath, slug: formattedSlug };
      }
      default:
        return { ...fallbackReturn, basePath, slug };
    }
  }
}
