import Head from 'next/head';
import Script from 'next/script';
import { useEffect } from 'react';

import type { DyContextPageType } from '@virginexperiencedays/dy';

const NEXT_PUBLIC_DY_SITE_ID = process.env.NEXT_PUBLIC_DY_SITE_ID;
/**
 * Some page context parameters are required, depending on page type.
 * @see {https://dy.dev/docs/page-context}
 */
type DynamicYieldArgs = {
  type: DyContextPageType;
  data?: string | string[];
};

function setDYContext(dyContextObject: DynamicYieldArgs) {
  global.DY = global.DY || {};
  global.DY.recommendationContext = dyContextObject;
}

const DynamicYield = ({ type, data }: DynamicYieldArgs) => {
  if (!NEXT_PUBLIC_DY_SITE_ID) console.error('Failed to initialise Dynamic Yield: missing site ID');

  useEffect(() => {
    const dyContextObject: DynamicYieldArgs = { type };
    if (data) dyContextObject.data = data;

    setDYContext(dyContextObject);
  }, [type, data]);

  return (
    NEXT_PUBLIC_DY_SITE_ID && (
      <>
        <Head>
          <link rel="preconnect" href="//cdn-eu.dynamicyield.com" />
          <link rel="preconnect" href="//st-eu.dynamicyield.com" />
          <link rel="preconnect" href="//rcom-eu.dynamicyield.com" />
          <link rel="dns-prefetch" href="//cdn-eu.dynamicyield.com" />
          <link rel="dns-prefetch" href="//st-eu.dynamicyield.com" />
          <link rel="dns-prefetch" href="//rcom-eu.dynamicyield.com" />
        </Head>
        <Script
          strategy="afterInteractive"
          src={`//cdn-eu.dynamicyield.com/api/${NEXT_PUBLIC_DY_SITE_ID}/api_dynamic.js`}
        ></Script>
        <Script
          strategy="afterInteractive"
          src={`//cdn-eu.dynamicyield.com/api/${NEXT_PUBLIC_DY_SITE_ID}/api_static.js`}
        ></Script>
      </>
    )
  );
};

export default DynamicYield;
